import React from 'react';
import { Responsive, Icon } from 'semantic-ui-react';

const HomePageIntro = () => {
  return (
    <div className="home-page-intro">
      <h3>Welcome to our website. Thanks for taking the time to visit us.</h3>

      <h4>
        East Lindfield Pharmacy has serviced the local community for over 60
        years. You can find us at 5 Hughes Place, East Lindfield.
      </h4>

      <Responsive minWidth={768} as="h5">
        We've included a summary of how to use our website in our help section.
        To view it, tap the HELP menu option at the top of any page.
      </Responsive>

      <Responsive maxWidth={767} as="h5">
        We've included a summary of how to use our website in our help section.
        To view it, tap{' '}
        <Icon name="help circle" className="home-page-intro__help" /> at the top
        of any page.
      </Responsive>

      <h5>
        In the meantime, here's some quick info about the pharmacy to get you
        started.
      </h5>
    </div>
  );
};

export default HomePageIntro;
