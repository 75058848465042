import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import ServicesWideScreenMenu from './ServicesWideScreenMenu';
import ServiceWideScreen from './ServiceWideScreen';

export class ServicesWideScreen extends Component {
  state = {
    selectedService: null
  };

  handleSelection = service => () => {
    this.setState({ selectedService: service });
  };

  render() {
    const { services } = this.props;
    const { selectedService } = this.state;

    return (
      <Grid padded className="services-widescreen">
        <Grid.Column tablet={9} computer={6} largeScreen={6}>
          <ServiceWideScreen service={selectedService} />
        </Grid.Column>

        <Grid.Column tablet={1} computer={2} largeScreen={2} />

        <Grid.Column tablet={6} computer={8} largeScreen={8}>
          <ServicesWideScreenMenu
            services={services}
            handleSelection={this.handleSelection}
            selectedService={selectedService}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  services: state.services
});

export default connect(mapStateToProps)(ServicesWideScreen);
