import React from 'react';
import { Responsive } from 'semantic-ui-react';
import Location from '../location/Location';

const AboutPageLocation = () => {
  return (
    <div className="about-page-location">
      <Responsive maxWidth={767}>
        <h1 className="section-heading">Where to find us</h1>
      </Responsive>
      <Location />
    </div>
  );
};

export default AboutPageLocation;
