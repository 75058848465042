import React from 'react';
import { Responsive } from 'semantic-ui-react';
import ContactInfo from '../contact/ContactInfo';

const AboutPageContact = () => {
  return (
    <div className="about-page-contact">
      <Responsive maxWidth={767}>
        <h1 className="section-heading">Ways to contact us</h1>
      </Responsive>
      <ContactInfo />
    </div>
  );
};

export default AboutPageContact;
