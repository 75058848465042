import React from 'react';
import { Icon, Responsive } from 'semantic-ui-react';
import Hours from '../hours/Hours';

const AboutPageHours = () => {
  return (
    <div className="about-page-hours">
      <Responsive maxWidth={767} as="h1" className="section-heading">
        Our business hours
      </Responsive>

      <div className="about-page-hours__items">
        <Icon className="about-page-hours__icon" name="clock outline" />
        <Hours />
      </div>
    </div>
  );
};

export default AboutPageHours;
