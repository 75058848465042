import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Responsive, Icon, Image } from "semantic-ui-react";
import { openModal } from "../../store/actions/modalActions";
import Logo from "../../assets/logo.png";
import { brand } from "../../data/constants";
import HeaderMenu from "./HeaderMenu";
import ContactInfoItem from "../contact/ContactInfoItem";

export class Header extends Component {
  state = {
    sideDrawerOpen: false
  };

  closeDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  openDrawer = () => {
    this.setState({ drawerOpen: true });
  };

  openHelpModal = () => {
    this.props.openModal("HelpModal");
  };

  render() {
    const { history, openSideDrawer } = this.props;

    return (
      <div className="header">
        <div className="header__main">
          <div className="header__main__logo">
            <Image src={Logo} alt="logo" as={NavLink} to="/" />
          </div>

          <Responsive as={Fragment} minWidth={768}>
            <HeaderMenu history={history} />
          </Responsive>

          <div className="header__main__menubar">
            <Responsive as={Fragment} maxWidth={767}>
              <Icon
                onClick={this.openHelpModal}
                name="help circle"
                className="header__main__menubar__help"
              />
            </Responsive>
            <Icon name="bars" size="small" onClick={openSideDrawer} />
          </div>
        </div>

        <div className="header__contact">
          <ContactInfoItem iconName="phone" detail={brand.TELEPHONE} />
          <ContactInfoItem iconName="mail" detail={brand.DEFAULT_EMAIL} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { openModal };

export default connect(
  null,
  mapDispatchToProps
)(Header);
