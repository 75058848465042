import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import AboutPageContact from './AboutPageContact';
import AboutPageEmployees from './AboutPageEmployees';
import AboutPageHours from './AboutPageHours';
import AboutPageIntro from './AboutPageIntro';
import AboutPageLocation from './AboutPageLocation';

const About = () => {
  return (
    <div className="about-page">
      <AboutPageIntro />

      <div className="about-page__container">
        <Responsive as={Fragment} minWidth={768}>
          <h1 className="container-heading">
            Location, hours and ways to contact us
          </h1>
        </Responsive>

        <AboutPageLocation />

        <div className="hours-contact-container">
          <AboutPageHours />
          <AboutPageContact />
        </div>
      </div>

      <AboutPageEmployees />
    </div>
  );
};

export default About;
