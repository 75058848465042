import React from 'react';
import EmployeeCards from '../employees/EmployeeCards';

const AboutPageEmployees = () => {
  return (
    <div className="about-page-employees">
      <h1 className="section-heading">Meet the team</h1>
      <EmployeeCards />
    </div>
  );
};

export default AboutPageEmployees;
