import React from 'react';
import HomePageIntro from './HomePageIntro';
import HomePageInfoCards from './HomePageInfoCards';
import HomePageCarousel from './HomePageCarousel';
import HomePageFooter from './HomePageFooter';

const Home = () => {
  return (
    <div className="home-page">
      <HomePageIntro />
      <HomePageInfoCards />
      <HomePageCarousel />
      <HomePageFooter />
    </div>
  );
};

export default Home;
