import React from "react";
import { brand } from "../../data/constants";
import ContactInfoItem from "./ContactInfoItem";

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <ContactInfoItem iconName="phone" detail={brand.TELEPHONE} />
      <ContactInfoItem iconName="fax" detail={brand.FAX} />
      <ContactInfoItem iconName="mail" detail={brand.DEFAULT_EMAIL} />
      <ContactInfoItem iconName="shipping fast" detail={brand.DELIVERY_EMAIL} />
    </div>
  );
};

export default ContactInfo;
