import React from 'react';

const AboutPageIntro = () => {
  return (
    <div className="about-page-intro">
      <h3 className="about-page-intro__primary">
        East Lindfield Pharmacy has serviced the local community for over 60
        years.
      </h3>
      <h4 className="about-page-intro__secondary">
        We are located at 5 Hughes Place in the East Lindfield shopping village
        next door to the IGA Plus. We are committed to providing exceptional
        service and providing for all your pharmacy needs.{' '}
      </h4>

      <h4 className="about-page-intro__secondary">
        One of our friendly, knowledgeable and professional pharmacists is
        always on hand to answer your questions and address any concerns you may
        have with your prescription or over the counter medicines and other
        health issues.
      </h4>

      <h4 className="about-page-intro__secondary">
        We pride ourselves on efficient and accurate prescription dispensing ,
        and the provision of up-to-date health advice. Have a look at our
        services page for more information on some of the services we provide.
      </h4>

      <h4 className="about-page-intro__secondary">
        The current owners, Elias and Alf, have been running the pharmacy since
        Dec 2018. With over 40 years experience in pharmacy between them, you
        can be assured that your health is in good hands.
      </h4>

      <h4 className="about-page-intro__secondary">
        Come in and say hello, we are looking forward to meeting you.
      </h4>
    </div>
  );
};

export default AboutPageIntro;
