import React from 'react';
import { Divider, Responsive } from 'semantic-ui-react';
import ServiceInfo from '../services/ServiceInfo';
import DeliveryInfo from '../delivery/DeliveryInfo';

const HomePageInfoCards = () => {
  return (
    <div className="home-page__info-cards">
      <ServiceInfo />
      <Responsive maxWidth={767} as={React.Fragment}>
        <Divider />
      </Responsive>

      <DeliveryInfo />
      <Responsive maxWidth={767} as={React.Fragment}>
        <Divider />
      </Responsive>
    </div>
  );
};

export default HomePageInfoCards;
