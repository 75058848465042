import React from 'react';
import EmployeeCardCarousel from '../employees/EmployeeCardCarousel';

const HomePageCarousel = () => {
  return (
    <div className="home-page__carousel">
      <h2 className="home-page__carousel__title">Our team</h2>
      <EmployeeCardCarousel />
    </div>
  );
};

export default HomePageCarousel;
